ol, ul, li {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0; /* bypass_csslint */
    vertical-align: baseline;
}
ol, ul {
    list-style: none outside none;
}
a {
    cursor: pointer;
}

input.default, input.placeholder {
    color: #999;
}

body .ui-widget {font: inherit;}
body .ui-widget input, body .ui-widget select, body .ui-widget textarea {font-family:Arial,sans-serif;}

body .ui-widget input#language, input#geography {
    border: none;
    box-shadow: none;
    margin: 0;
}

body .ui-helper-clearfix .bootstrap-tagsinput {
    width: 250px;
}

body .bootstrap-tagsinput .label-info {
    background-color: #A9A9A9;
}

body .dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
    background-color: #A9A9A9;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#D3D3D3), to(#A9A9A9));
    background-image: -webkit-linear-gradient(top, #D3D3D3, #A9A9A9);
    background-image: -moz-linear-gradient(top, #D3D3D3, #A9A9A9);
    background-image: -o-linear-gradient(top, #D3D3D3, #A9A9A9);
    background-image: linear-gradient(to bottom, #D3D3D3, #A9A9A9);
    background-repeat: repeat-x;
}

body .ui-widget .languageLabel, .geographyLabel{
    padding-left: 10px;
    color: #333;
    margin-top: 10px;
}

.sr-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.menu_body {
    position:absolute;
    bottom:0;
    right:0;
    width:0;
    height:0;
    display:block;
}

#district_selector_section {
    max-height: 500px;
    overflow-y: auto;
}

#district_selector_section ul.district_menu,
.menu_body ul.district_menu {
    border: 1px solid #D3D3D3;
    float: left;
}

#group_manager .pag_v2_page > .page-arrow {
    background: none;
    border: none;
}

#group_manager .pag_v2_page > .page-input {
    padding: 4px 6px;
    width: auto;
}

#group_search_box .form-inline input[type="text"],
#new_district_name {
    /*
        * this input is generated by a JS package
        * these rules are a copy of BS3's .form-control
    */
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

body .node_name .fg-button {
    background: none repeat scroll 0 0 #ECECEC;
    border: 1px solid #D3D3D3;
    color: #555555;
    font-weight: normal;
    padding: 5px;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    width: 150px;
}

body .ui-widget-content {border:none;}
body .ui-widget-content a {color: #555;}
body .ui-dialog {padding:0 0 6px;border:0;-moz-border-radius:6px;-webkit-border-radius:6px;border-radius:6px;}
body .ui-dialog .ui-dialog-content {padding:10px 18px 0;text-align:left;background:#ffffff;}
body .ui-dialog .ui-dialog-titlebar {padding:0.6em 20px 0.4em;font-size:18px;background:#f1f2f2;border:none;
    -moz-border-radius-topleft:6px;-webkit-border-top-left-radius:6px; border-top-left-radius:6px;-moz-border-radius-topright:6px;-webkit-border-top-right-radius:6px;border-top-right-radius:6px;
    -moz-border-radius-bottomleft:0;-webkit-border-bottom-left-radius:0;border-bottom-left-radius:0;-moz-border-radius-bottomright:0;-webkit-border-bottom-right-radius:0;border-bottom-right-radius:0;
}
body .ui-dialog .ui-dialog-titlebar-close {
    margin-right:11px;
    background: url("../images/external/glyphicons-halflings.png") -311px 160px;
}
body .ui-dialog .ui-state-hover {background-image:none;}
body .ui-widget-overlay {background:#333 url(../images/ui-bg_flat_0_333333_40x100.png) 50% 50% repeat-x;opacity:.6;filter:Alpha(Opacity=60);}

/* added for multi district hierarchy */
body .ui-menu-item .ui-state-focus { background-color: #86898B; }
body .ui-menu-item li { width: 600px; }
body .ui-menu-item a { color: #555; }
body .ui-menu-item .ui-icon.ui-icon-carat-1-e { float: right; height: 16px; }
body .ui-menu-item .ui-icon.ui-icon-carat-1-w { float: left; }
body .ui-menu-back-link { float: left; }
body .fg-button { float: left; }

#ui-datepicker-div {
    background:#c8cacb;
    border:none;
    padding:6px;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
    border-radius:6px;
    -moz-box-shadow:2px 2px 0 #86898B;
    -webkit-box-shadow:2px 2px 0 #86898B;
    box-shadow:2px 2px 0 #86898B;
}
#ui-datepicker-div .ui-datepicker-header {background-color:#86898b;border:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;background-image:none;color:#FFF;}
#ui-datepicker-div thead th {background:transparent;color:#86898b;padding:0.3em;}
#ui-datepicker-div td {padding:2px;}
#ui-datepicker-div td .ui-state-default {border-color:#86898b;border-width:1px 0 0 1px;border-style:solid;}
#ui-datepicker-div td .ui-state-active {background-color:#a8cb17;color:#fff;}
#ui-datepicker-div .ui-state-default {background:#FFFFFF;color:#333;}
#ui-datepicker-div .ui-state-hover {background-image:none;}
#ui-datepicker-div td .ui-state-hover {background-color:#e5f897;}


/* -------------------------------------------------------------------------
 * Content Popup
 * -------------------------------------------------------------------------
 */

.popup_base .popup_content {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    position: relative;
    min-height: 400px;
    padding: 12px 24px 24px;
    margin-top: 20px;
}

.popup_base li {
    margin: 4px 0;
}

.popup_base ul {
    margin: 16px 0 16px 20px;
}

.popup_base label {
    cursor: pointer;
}

.popup_base .button {
    margin: 18px 18px 18px 0;
    min-width: 140px;
}

.popup_base .button-action-loader {
    margin: 0 0 -5px -10px;
}

.popup_base h1 {
    font-size: 22px;
    margin: 16px 0;
}

.popup_base p {
    margin: 16px 0;
}

body.setup_base, body.popup_base {
    background-color: #FAFBFB;
}

#nps {
    border-top: 4px solid #D32F0B;
}

#grant_perms_banner {
    margin: 12px 0 0;
}

#grant_perms_banner h3 {
    font-weight: bold;
}

.container-main {
    margin-top: 12px;
}

/* -------------------------------------------------------------------------
    * Left Side
    * -------------------------------------------------------------------------
    */
.organization-logo {
    padding: 10px 0;
}

.organization-logo img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 210px;
}

.left-side {
    font-size: 14px;
}

.left-side h3 {
    font-size: 1.2em;
    margin-bottom: 7px;
    clear: both;
}

.left_side_section {
    padding: 0 10px 0 0;
}

.left_side_separator {
    border-top: 1px solid #DDDEDE;
    margin-top: 15px;
    padding-top: 15px;
}

.left_side_separator_no_border {
    height:24px;
    position:relative;
    right:10px;
    width:194px;
    float:left;
}

/* -------------------------------------------------------------------------
    * Right Side
    * -------------------------------------------------------------------------
    */

div.right-side {
    padding: 10px 0 0 20px;
    position: relative;
    /*
    this is a 1x1 pixel color #e4e4e4
    we need to use a background image because zooming issues
    on some browsers was causing the main column to wrap.
    http://markasunread.com/2009/06/zoom-bug-in-ie78-and-firefox-caused-by-border/

    data-urls are not supported in ie7 so IE7 does not have a border.
    so we use a real image in this case
        */
    background: #fff 0 0 url('data:image/gif;base64,R0lGODlhAQABAIAAAOTk5AAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==') repeat-y;
    *background-image: url('../images/pixel-e4e4e4.gif');  /* <= IE7 bypass_csslint */
    margin-left: 0;
    min-height: 500px;
}

h1 {
    font-size:22px;
    margin-bottom:.8em;
    line-height: 26px;
}

.right-side h1 span {
    color:#616161;
}
.right-side h1 sup {font-weight: normal;font-size: .6em;color: #616161;vertical-align: super;}
.right-side h2 {margin:20px 0 20px;padding-top:20px;border-top:1px solid #DDDEDE;font-weight:bold;}

.clear {
    clear: both;
}

body {
    height: 100%;
}

body.app {
    min-width: 992px;
}

/* Headings
-------------------------------------------------------------- */
h3,h4 {font-weight:normal;}

/* TODO: nuke me when we stop overriding header font-size */
h2, h3 {
    line-height: 20px;
}

h2 {
    font-size:22px;
    margin:0 0 16px;
    clear:both;
}
h3 {
    font-size:16px;
    margin-bottom:10px;
}

h4 { font-size: 1.2em; line-height: 1.25; margin-bottom: 1.25em; }
h5 { font-size: 1em; margin-bottom: 1.5em; }
h6 { font-size: 1em; }

h1 img, h2 img, h3 img,
h4 img, h5 img, h6 img {
    margin: 0;
}

h2 a {
    text-decoration: underline;
}

h2 .breadcrumb {
    color: #000000;
}


/* HSS Overrides to match headings with Bootstrap styles
for newer features in app
----------------------------------------------------------- */

h2.hss-override-h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 10px 0;
}

h3.hss-override-h3 {
    font-size: 24px;
    line-height: 40px;
}




/* Text elements
-------------------------------------------------------------- */
p img.left  { float: left; margin: 1.5em 1.5em 1.5em 0; padding: 0; }
p img.right { float: right; margin: 1.5em 0 1.5em 1.5em; }

abbr { border-bottom: 1px dotted #666; }

/* Tables
-------------------------------------------------------------- */

table       { margin-bottom: 1.4em; width:100%; }
th          { font-weight: bold; }
th,td,caption { padding: 4px 10px 4px 5px; }
tr.even td  { background: #f4f4f4; /*#e5ecf9;*/ }

/* Misc classes
-------------------------------------------------------------- */

.small      { font-size: .8em; margin-bottom: 1.875em; line-height: 1.875em; }
.large      { font-size: 1.2em; line-height: 2.5em; margin-bottom: 1.25em; }
.bold       { font-weight: bold; }
.first      { margin-left:0; padding-left:0; }
.last       { margin-right:0; padding-right:0; }
.body-text-small { font-size: 12px; } /*Added to sub for small HTML tag*/

div.gray-box {padding:10px 10px;background-color:#555;}
div.gray-box-inside {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
    border-bottom: 1px solid #D1D3D4;
    border-right: 1px solid #D1D3D4;
    background-color:#fff;
    padding:10px;
    margin-bottom:10px;
    line-height:16px;
}
div.gray-box-controls {
    text-align:right;
    padding-top:10px;
    height:39px;
}
div.gray-box-controls input {
    margin-left:1em;
}

.gray-box-controls a.button {
    display: inline-block;
}

.rounded {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
}

.rounded-shadow {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    border-radius: 4px;
    border-bottom: 1px solid #D1D3D4;
    border-right: 1px solid #D1D3D4;
}

/* Form fields
-------------------------------------------------------------- */
.date_time_picker {vertical-align:middle;}
.date_time_picker select {width:inherit;}
input.datetime {
    width: 500px;
}
input.datetime.date {width:80px;background-image:url(../images/icons_sprite_v26.png);background-position: 6px -713px;background-repeat:no-repeat;padding-left:26px;}
input.datetime.days {width:40px;}
input.datetime.time {width:64px;}

/* Success, notice and error boxes
-------------------------------------------------------------- */

.notice     { background: #FFF6BF; color: #514721; border-color: #FFD324; }
.success    { background: #E6EFC2; color: #264409; border-color: #C6D880; }
.notice a   { color: #514721; }
.success a  { color: #264409; }


/* -------------------------------------------------------------------------
    * tooltip image sprite
    * -------------------------------------------------------------------------
    */
.tooltip {font-weight:normal;}

/* -------------------------------------------------------------------------
    * pagination v2 sprites
    * -------------------------------------------------------------------------
    */

.paginationV2 {
    margin-bottom: 12px;
}

.paginationV2 .pag_v2_page {
    float:right;
    text-align: right;
    width:200px;
}

.paginationV2 .pag_v2_display {
    float:left;
    width:400px;
}

.paginationV2 .page_num {
    cursor: pointer;
    border: 1px solid #b7b8b9;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    display: inline-block;
    width: 26px;
    height: 16px;
    text-align: center;
    vertical-align: middle;
    padding: 0 2px;
    margin-right: 4px;
    padding-bottom: 3px;
}

.paginationV2 .page_num.selected {
    background-color: #f1f2f2;
}

.paginationV2 .page_num:hover {
    background-color: #86898b;
    color: #FFF;
}

.paginationV2 input.page-input {
    text-align: center;
    background:#F1F2F2;
    width:30px;
}

.paginationV2 .pag_v2_page .page-arrow {
    margin-bottom: 4px;
    cursor: pointer;
}

.paginationV2 .inactive {
    visibility: hidden;
}

.paginationV2 i {
    cursor: pointer;
}

#content_browse_pagination_top {
    margin-bottom: -5px;
}

#content_browse_pagination_top .paginationV2 {
    float: right;
    width: 100%;
}

.infinitescroll {
    background: url("../images/ajax-loader.gif") no-repeat scroll 10px white;
    padding-left: 32px;
    height: 24px;
    border: 1px solid #CDCDCD;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    line-height: 24px;
}

.finished {
    background-image: none;
}

/* -------------------------------------------------------------------------
    * Common/global sprites
    * -------------------------------------------------------------------------
    */

.dismiss_icon {
    background: url(../images/compliance_icons.png) no-repeat 0 0;
    width: 12px;
    height: 12px;
    float: left;
}

.assign_queue_icon {
    background: url(../images/compliance_icons.png) no-repeat 0 -160px;
    width: 16px;
    height: 16px;
    float: left;
}

.email_icon {
    background: url(../images/compliance_icons.png) no-repeat 0 -40px;
    width: 16px;
    height: 16px;
    float: left;
}

.block_user_icon {
    background: url(../images/compliance_icons.png) no-repeat 0 -80px;
    width: 16px;
    height: 16px;
    float: left;
}

.delete_post_icon {
    background: url(../images/compliance_icons.png) no-repeat 0 -120px;
    width: 16px;
    height: 16px;
    float: left;
}

/* -------------------------------------------------------------------------
    * Profile box
    * -------------------------------------------------------------------------
    */
div.profile_img_box { display: block; float: left; width: 70px; background: #86898B; }
div.profile_img_box img { width: 50px; height: 50px; border: 1px solid #FFFFFF; margin-left: 10px; margin-right: 10px; }
div.profile_img_box img.first { margin-top: 10px; }
div.profile_img_box img.middles { margin-top: 2px; margin-bottom: 0; }
div.profile_img_box img.last { margin-bottom: 4px; }
/* image height and margin-top add up to 36px - so there is always a 36px space at the bottom of the profile_img_box for the social network */
div.profile_img_box div { background: url(../images/network_ghost_icons_v1.png) no-repeat 0 0; float:right; width: 20px; height: 36px;}
div.profile_img_box div.facebook_profile_box { background-position: 0 0; width: 20px; height: 36px;}
div.profile_img_box div.twitter_profile_box { background-position: 0 -50px; width: 24px; height: 32px; margin-top: 4px; }
div.profile_img_box div.linkedin_profile_box { background-position: 0 -100px; width: 34px; height: 30px; margin-top: 6px; }
div.profile_img_box div.googleplus_profile_box { background-position: 0 -150px; width: 43px; height: 33px; margin-top: 3px; }

/* -------------------------------------------------------------------------
    * Search Contacts
    * -------------------------------------------------------------------------
    */
.content-search {
    float: right;
}
.content-search input {
    width: 169px;
}

.content-search .content-search-button {
    cursor: pointer;
}

.contact-row {
    height: 35px;
    line-height: 12px;
    padding-top: 4px;
    white-space: nowrap;
}

.contact-row img {
    float: left;
    padding-right: 4px;
    margin-top: -4px;
    height: 35px;
    width: 35px;
}

.contact-row span {
    padding-top: 4px;
    white-space: normal;
    padding-right: 1em;
}

.closed {display: none;}

.notes_small {
    background-image: url(../images/note_icon.png);
    height: 25px;
    width: 26px;
}
.facebook_16x16_icon,
.fbpage_16x16_icon,
.fbprofile_16x16_icon,
.twitter_16x16_icon,
.linkedin_16x16_icon,
.linkedin_group_16x16_icon,
.linkedin_company_16x16_icon,
.google_16x16_icon,
.google_plus_16x16_icon,
.google_page_16x16_icon,
.google_profile_16x16_icon,
.googlePage_16x16_icon,
.googleProfile_16x16_icon,
.yelp_16x16_icon,
.youtube_16x16_icon,
.poly_16x16_icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: transparent;
    background-image: url(../images/icons_sprite_v26.png);
    background-repeat: no-repeat;
}

.facebook_16x16_icon,
.fbpage_16x16_icon,
.fbprofile_16x16_icon {background-position: 0 0;}
.twitter_16x16_icon {background-position: 0 -20px;}
.linkedin_16x16_icon,
.linkedin_company_16x16_icon,
.linkedin_group_16x16_icon {background-position: 0 -40px;}
.google_16x16_icon,
.google_plus_16x16_icon,
.googleProfile_16x16_icon,
.googlePage_16x16_icon,
.google_profile_16x16_icon,
.google_page_16x16_icon {background-position: 0 -560px;}
.yelp_16x16_icon {background-position: 0 -1758px;}
.youtube_16x16_icon {background-position: 0 -1518px;}



.facebook_16x16_icon.icon_gray,
.fbpage_16x16_icon.icon_gray,
.fbprofile_16x16_icon.icon_gray {background-position: 0 -100px;}
.twitter_16x16_icon.icon_gray {background-position: 0 -120px;}
.linkedin_16x16_icon.icon_gray,
.linkedin_company_16x16_icon.icon_gray,
.linkedin_group_16x16_icon.icon_gray {background-position: 0 -140px;}
.google_16x16_icon.icon_gray,
.google_plus_16x16_icon.icon_gray,
.google_profile_16x16_icon.icon_gray,
.google_page_16x16_icon.icon_gray {background-position: 0 -580px;}
.yelp_16x16_icon.icon_gray {background-position: 0 -1775px;}
.youtube_16x16_icon.icon_gray {background-position: 0 -1535px;}


.facebook_16x16_icon.icon_lite,
.fbpage_16x16_icon.icon_lite,
.fbprofile_16x16_icon.icon_lite {background-position: 0 -1596px;}
.twitter_16x16_icon.icon_lite {background-position: 0 -1613px;}
.linkedin_16x16_icon.icon_lite,
.linkedin_company_16x16_icon.icon_lite,
.linkedin_group_16x16_icon.icon_lite {background-position: 0 -1630px;}
.google_16x16_icon.icon_lite,
.google_plus_16x16_icon.icon_lite,
.google_profile_16x16_icon.icon_lite,
.google_page_16x16_icon.icon_lite {background-position: 0 -1664px;}
.yelp_16x16_icon.icon_lite {background-position: 0 -1792px;}
.youtube_16x16_icon.icon_lite {background-position: 0 -1699px;}

.facebook_20x20_icon,
.fbpage_20x20_icon,
.fbprofile_20x20_icon,
.twitter_20x20_icon,
.twitter_bird_20x20_icon,
.linkedin_20x20_icon,
.linkedin_company_20x20_icon,
.linkedin_group_20x20_icon,
.google_plus_20x20_icon,
.google_20x20_icon,
.poly_20x20_icon,
.all_20x20_icon,
.google_page_20x20_icon,
.google_profile_20x20_icon,
.yelp_20x20_icon,
.youtube_20x20_icon {display: inline-block; height: 20px; width: 20px; background-color: transparent; background-image: url(../images/icons_sprite_v26.png); background-repeat: no-repeat;}

.facebook_20x20_icon,
.fbpage_20x20_icon,
.fbprofile_20x20_icon {background-position: 0 -1160px;}
.twitter_20x20_icon {background-position: 0 -1180px;}
.linkedin_20x20_icon,
.linkedin_company_20x20_icon,
.linkedin_group_20x20_icon {background-position: 0 -1200px;}
.google_20x20_icon,
.google_plus_20x20_icon,
.google_profile_20x20_icon,
.google_page_20x20_icon {background-position: 0 -1240px;}
.yelp_20x20_icon {background-position: 0 -1716px; }
.youtube_20x20_icon {background-position: 0 -1553px;}

.all_20x20_icon {background-position: 0 -1260px;}

.facebook_20x20_icon.icon_gray,
.fbpage_20x20_icon.icon_gray,
.fbprofile_20x20_icon.icon_gray {background-position: 0 -1280px;}
.twitter_20x20_icon.icon_gray {background-position: 0 -1300px;}
.linkedin_20x20_icon.icon_gray,
.linkedin_company_20x20_icon.icon_gray,
.linkedin_group_20x20_icon.icon_gray {background-position: 0 -1320px;}
.google_20x20_icon.icon_gray,
.google_profile_20x20_icon.icon_gray,
.google_page_20x20_icon.icon_gray {background-position: 0 -1360px;}
.all_20x20_icon.icon_gray {background-position: 0 -1380px;}
.yelp_20x20_icon.icon_gray {background-position: 0 -1737px; }
.youtube_20x20_icon.icon_gray {background-position: 0 -1574px;}
.poly_20x20_icon {background-position: 0 -1260px;}

.hss-network-icon {
    margin-right: 6px;
    display: inline-block;
}

.hss-network-icon .hss-facebook-square,
.hss-network-icon .hss-twitter-square,
.hss-network-icon .hss-linkedin-square,
.hss-network-icon .hss-apollo-square,
.hss-network-icon .hss-youtube-square,
.hss-network-icon .hss-google-plus-square,
.hss-network-icon .hss-google-square,
.hss-network-icon .hss-yelp-square,
.hss-network-icon .hss-poly-square,
.hss-network-icon .hss-third_party-square {
    margin-bottom: 1px;
}

#loader{ margin:16px 7px 7px 7px;}

.ui-dialog .ui-dialog-content {text-align: center; margin: 0 auto;}
.ui-widget button {font-family: Helvetica,Arial,sans-serif;}

/* PAGINATION CSS STYLES */
.pagination_section {
    clear: both;
    margin: 0 auto;
    text-align: center;
    padding: 15px;
}

.pagination .prev,
.pagination .next {
    position: relative;
    top: 0;
}

.pagination a.number, .pagination span {
    display:inline-block;
    border:1px solid transparent;
    min-width:16px;
    height:18px;
    line-height:18px;
    text-align:center;
    text-decoration: underline;
    color: #0064B1;
    vertical-align:middle;
    margin:0 1px;
    padding:0 2px;
}
.pagination a:hover {
    border-color:#DDDEDE;
}
.pagination span {
    color: inherit;
    text-decoration:none;
}

.pagination .current {
    font-weight: bold;
}

/* END OF PAGINATION CSS STYLES */

.status {
    float: right;
    padding: 0 22px 0 22px;
    position: relative;
    text-align: left;
    top: 0;
    width: 638px;
}

.status .compliance_ok {
    text-align: center;
    margin: 0 auto 15px auto;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 5px 5px 5px;
}

.status table {
    font-size: 12px;
    border: 0;
    margin: 0;
}
.status tr,
.status td,
.status th {
    border: 0;
    vertical-align: top;
}
.status tr.even { background-color: #fff; }
.status tr.odd { background-color: #fff; }
.status th { font-size: 14px; }

.description { padding:10px 0 0 21px; }
#content_library_section li { width: auto; }

.content_library_landing {
    clear:both;position:relative;padding-top:20px;
}
.content_library_landing #createnew {
    position:absolute;top:57px;right:0;float:none;

}.content_library_landing a.newPost {
    position:absolute;top:57px;right:0;float:none;
}

#recent_activities .activity { margin-bottom: 10px; }
#recent_activities .activity img { float:left;height:35px;width:35px;}
#recent_activities .meta { float:left; padding-left:7px; width:167px; overflow:hidden;}
#recent_activities .preview { display:block;}

.stat { margin-bottom: 7px; width:auto; }
.stat .network-icon { float: left; }
.stat a { display: block; float: left; width: 100px; }
.statChange { display: inline-block; text-align: right; width: 24px; }
.statChangeType { display: inline-block; width: 10px; }
.statRecord { display: inline-block; width: 40px; }
.positive { background:url(../images/green_arrow.png) no-repeat 0 0; height: 10px; width: 10px; display: inline-block; }
.negative { background:url(../images/red_arrow.png) no-repeat 0 0; height: 10px; width: 10px; display: inline-block; }

#modal-overlay {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position:fixed;
    top: 0;
    background: #000;
    filter: alpha(opacity=70)!important; /* bypass_csslint */
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
    display: none;
    z-index: 299;
}

img.char_counter { float:left; margin-right:5px; }
.char_counter { padding-left: 21px;font-size: 17px; }
.char_icon_limiter { margin-left: 15px; }
#content_link { border:solid 1px #b9b8b9; float:left; padding:6px 5px; font-size:12px; color:#696969; margin-right:2px; }
input#attach {width:75px;padding-right:0;padding-left:0;}
input#refresh {margin: -10px 0 12px 31px;}

#send_for_approval {float:right;margin-top:-16px;}

#load_link, .load_link {vertical-align:middle;display:none;float:left;}
#load_link.loading, .load_link.loading {display:inline;}
#empty_picture {display:none;min-height:120px;width:135px;padding:5px 0 0;}

#users_popup .user_search_name {
    width: 548px;
}

/* General search_popup css */
.search_popup ul { height:318px; overflow-y:auto; overflow-x:hidden; padding: 0; background:#FFF; clear:both; border:solid 1px #DDDEDE; margin:8px 0 14px 0; }
.search_popup li { border-bottom:solid 1px #DDDEDE; padding:7px 6px; height:49px; }
.search_popup li.first { border:none; }
.search_popup li.more { border:none; }
.search_popup li img { float:left; margin-right:10px; max-height:49px; }
.search_popup li img.loading { display: block; float:none; margin:15px auto; }
.search_popup li a { display:block; height:32px; }
.search_popup li span { padding-bottom:3px; }

.center {
    margin-left: auto;
    margin-right: auto;
    float: left;
}

.regionManagement .row {margin-top: 1.2em;}
.regionManagement select {width:324px;}
.regionManagement a.removeButton,
.regionManagement a.addButton {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../images/icon_add.gif) no-repeat;
    text-indent: -9999px;
    direction: ltr;
    vertical-align: top;
}
.regionManagement a.removeButton {
    background-image: url(../images/icon_remove.gif);
}

#settings_content_tags .widgetbox-scroll {width: 488px;}
.contentCategories p { padding:0; width:425px; }
.contentCategories div {clear:both;}
.contentCategories div.addContentCategory { padding-bottom: 10px; }
.contentCategories input.contentCategory { width:391px; }
.contentCategories .scroll {
    border:solid 1px #aaa;
    background:url(../images/bg_input.gif) repeat-x;
    font-size:12px;
    padding:5px;
    width:404px;
    height:155px;
    overflow:auto;
    position:relative;
    float:left;
    color:#222;
    margin-bottom:16px;
}

.right-side ul.infractions {float:none;list-style:square outside none;padding-left:1.2em;}
.right-side ul.infractions li {float:none;padding:0;margin:0;font-weight:normal;vertical-align:top;}

.list_sequence { margin-top: 3px;}

.content_item_tags { clear: both; margin-top: 10px; color: #222; line-height: 25px;}
.content-tag-link { padding: 4px; background-color: #F1F2F2; }
.filterBy {float:left;}
.filterBy li { display: inline; }

ul.ui-autocomplete {
    text-align:left;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-color: #dedede;
    border-width: 0 1px 1px;
    padding: 0;
}
ul.ui-autocomplete {
    z-index: 2000;
}
ul.ui-autocomplete .ui-menu-item a {
    padding-top: 2px;
    padding-bottom: 0;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #616161;
}
ul.ui-autocomplete .ui-menu-item a.ui-state-hover,
ul.ui-autocomplete .ui-menu-item a:hover {
    border: 0;
    background: #dedede;
    margin: 0;
    color: #616161;
}

input.ui-autocomplete-loading {background:#FFF url(../images/ajax-loader-input.gif) right no-repeat;}
.ui-autocomplete {
    max-height: 200px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}
/* IE 6 doesn't support max-height
    * we use height instead, but this forces the menu to always be this tall
    */
* html .ui-autocomplete {height:200px;}
input.default {color:#999;}

.tab-regions select {margin-bottom:6px;margin-right:6px;}
.tab-regions #regions-selector {float:left;display:block;position:relative;}
.tab-regions .regions-selector-add {bottom:10px;right:0;margin-left:5px;}
.tab-regions .regions-added {font-size:12px;clear:both;padding-top:8px;}
.tab-regions .regions-added a {color:#555555;font-weight:bold;font-size:14px;padding-left:3px;}
.tab-regions .regions-added a:hover {text-decoration:none;}
.tab-regions .regions-added > span {
    padding:0 5px;
    display:inline-block;
    background:none repeat scroll 0 0 #E1E2E3;
    border:1px solid #CDCECF;
    white-space:nowrap;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    margin:6px 6px 0 0;
}

.tab-items select {margin-bottom:6px;margin-right:6px;}
.tab-items #items-selector {float:left;display:block;position:relative;}
.tab-items .items-selector-add {bottom:10px;right:0;margin-left:5px;}
.tab-items .items-added {font-size:12px;clear:both;padding-top:8px;}
.tab-items .items-added a {color:#555555;font-weight:bold;font-size:14px;padding-left:3px;}
.tab-items .items-added a:hover {text-decoration:none;}
.tab-items .items-added > span {
    padding:0 5px;
    display:inline-block;
    background:none repeat scroll 0 0 #E1E2E3;
    border:1px solid #CDCECF;
    white-space:nowrap;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    margin:6px 6px 0 0;
}

.tab-items .items-added-v1 {
    font-size:13px;
    padding: 0 5px 5px 5px;
    clear:both;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f5f5f5;
}
.panel .region-select {margin-top:7px;}
.panel .item-select {margin-top:7px;}

.footer .pipe {padding: 0 8px;}
.footer {
    font-size: 12px;
    padding: 30px 0 10px;
}
.footer .center-block {
    width: 90%;
    margin: auto;
    text-align: center;
}

/* -------------------------------------------------------------------------
 * Invites
 * -------------------------------------------------------------------------
 */
.page-group-settings h3 {
    margin-top: 12px;
}

.page-group-settings ul.list {
    margin-bottom: 16px;
}

.page-group-settings span.email {
    display: block;
    float: left;
    width: 250px;
}

.page-group-settings span.invite_type {
    display: block;
    float: left;
    width: 150px;
}

.page-group-settings span.invited_by {
    display: block;
    float: left;
    width: 150px;
}

.page-group-settings span.date {
    display: block;
    float: left;
    width: 70px;
    text-align: right;
    padding-right: 10px;
}

.page-group-settings ul.list li {
    display: inline-block;
    padding: 0;
    clear: both;
}

.page-group-settings .inviteUsers {
    display: none;
}

.page-group-settings #invites_pending .remove:hover {
    text-decoration: none;
}

.validation_overlay {
    -moz-opacity:0; -khtml-opacity: 0; opacity: 0;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    -moz-box-shadow: 0 3px 3px #aaa;
    -webkit-box-shadow: 0 3px 3px #aaa;
    box-shadow: 0 3px 3px #aaa;
    border-radius: 4px;
    border-bottom: 1px solid #bbb;
    border-right: 1px solid #bbb;
    width: 420px;
    background-color: #DEDEDE;
    padding: 16px;
    position: absolute;
    text-align: left;
    z-index: 20000;
    margin-top: 10px;
    display:none;
}

.actions .validation_overlay { right: 0; }

.exaggeration {
    font-size: 50pt;
    line-height: 50px;
    color: #FF8811;
    float:left;
    width:30px;
    margin-top: 6px;
}
.validation_success { color: #22BBFF; }
.validation_message { float:right; width:380px; }
.validation_message p { margin-bottom: 12px; }
.validation_overlay h2 { font-size:18px; font-weight: bold; margin:0 0 16px; clear:both; }

input.message {
    color: #999;
    border: 1px solid #DDDEDE;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    width: 250px;
    font-size: 12px;
}

.status-bubble {position:absolute;top:-13px;right:-4px;background-color:#86898b;color:#FFF;display:block;width:80px;padding:4px 5px 3px;text-align:center;font-weight:bold;-moz-border-radius:4px;-webkit-border-radius:4px;border-radius:4px;text-transform:capitalize;
    -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);}
.status-bubble:after {
    border-color: #86898b transparent;
    border-style: solid;
    border-width: 10px 0 0 10px;
    top: 23px;
    content: "";
    display: block;
    right: 63px;
    position: absolute;
    width: 0;
}
.status-bubble.status-draft {background-color:#0064b1;}
.status-bubble.status-draft:after {border-color:#0064b1 transparent;}

.status-bubble.status-live {background-color:#99C414;}
.status-bubble.status-live:after {border-color:#99C414 transparent;}

.status-bubble.status-error {background-color:#000;}
.status-bubble.status-error:after {border-color:#000 transparent;}

.status-bubble.status-ended, .status-bubble.status-expired {background-color:#B72921;}
.status-bubble.status-ended:after, .status-bubble.status-expired:after {border-color:#B72921 transparent;}

#image_loading {vertical-align:middle;display:none;}

.query-stats {
    position: fixed;
    right: 5px;
    bottom: 5px;
    text-align: right;
    background: white;
    z-index: 10;
    padding: 5px;
    color: #888;
    line-height: 15px;
}

.form-standard .invite-multiple { resize: vertical }

.invite-email-error { font-weight: bold; margin-bottom: 10px; }
/*****************************************************************************
    * ENTERPRISE SETTINGS ********************************************************
    *****************************************************************************/

/* Buttontrack - For slider/toggle buttons */
.toggle-shield {
    display: none;
    position: absolute;
    background: #fff;
    height: 30px;
    filter: alpha(opacity=10);
    -ms-filter: “alpha(opacity=10)”;
    opacity: 0.1;
    width: 102px;
    left: 340px;
    z-index: 1;
}
.toggle-shield.activate {
    display: inline-block;
}
.bootstrap-switch-disabled {
    pointer-events: none;
}
.buttontrack {
    width: 76px;
    height: 21px;
    border-radius: 12px;
    border: 1px solid #CDCECF;
    background: none repeat scroll 0 0 #E1E2E3;
    overflow: hidden;
    margin: 0;
    float: left;
    position: relative;
    z-index: 0;
    cursor: pointer;
    text-shadow: 0 1px 0 rgba(247, 247, 247, 0.6);
}
.buttontrack .label {
    font-family: Helvetica, Arial, sans-serif;
    color: #555555;
    font-size: 12px;
    position: absolute;
    top: 4px;
    width: 76px;
    z-index: 10;
}
.buttontrack .label .on {
    text-align: left;
    float: left;
    padding-left: 12px;
}
.buttontrack .label .off {
    text-align: right;
    float: right;
    padding-right: 12px;
}
.buttontrack .button {
    border-radius: 10px;
    background: none repeat scroll 0 0 #9CC0D5;
    border: 1px solid #8CABBD;
    padding: 0;
    width: 35px;
    height: 17px;
    text-align: center;
    cursor: pointer;
    clear: both;
    position: relative;
    z-index: 5;
}
.buttontrack .buttonhover {
    background: none repeat scroll 0 0 #3680AC;
    border: 1px solid #2D6F94;
}
.buttontrack .left {
    margin: 1px 1px 1px 1px;
}
.buttontrack .right {
    margin: 1px 1px 1px 38px;
}
.buttontrack .deselected {
    color: #555;
}
.buttontrack .selected {
    color: #1B4056;
}
.buttontrack .texthover {
    color: #FFF;
    text-shadow: 0 1px 0 rgba(27, 64, 86, 0.5);
}

.form-standard {background:#F1F2F2;padding:40px 20px 20px;}
.section {margin-bottom:20px;}

.notification_banner {
    margin-bottom: 26px;
    margin-top: 5px;
    padding: 10px;
    background-color: #F1F2F2;
    overflow: hidden;
    overflow-wrap: break-word; /* bypass_csslint */
    word-wrap: break-word;
}

.notification_banner .remove_message {
    cursor: pointer;
    font-weight: bold;
    float: right;
}

div.notification_banner a {
    color: #0064B1;
}

.notification_banner .message li {
    margin: 15px 0 0 10px;
}

.notification_banner .message p {
    margin-top: 10px;
    line-height: 17px;
}

.error_title {
    font-weight:bold;
    font-size:16px;
}

.red {
    color:#DD3F0F;
}

.yellow {
    color:#DDA50E;
}

.border_red {
    border:1px solid #DD3F0F;
}
.border_yellow {
    border:1px solid #DDA50E;
}

.blue {
    color:#00C1FB;
}

.border_blue {
    border:1px solid #00C1FB;
}

.green {
    color:#A8CB17;
}

.border_green {
    border:1px solid #A8CB17;
}

.notif-banner-dialog-footer {
    float: right;
}

.notif-banner-dialog-error {
    display: inline-block;
    color: #d9534f;
}

.notif-banner-dialog-phone-label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 10px;
}

.notif-banner-dialog-phone-input {
    display: inline-block;
    vertical-align: middle;
}

.notif-banner-dialog-send-button {
    margin-top: 5px;
    margin-bottom: 5px;
}

.banner-msg {
    margin: 20px;
}

.notif-banner-button {
    margin-top: 5px;
    margin-bottom: 5px;
}

.notif-banner {
    float:right;
}

.notif-banner:hover{
    margin-top: 12px;
    padding: 12px;
}

.banner-notification{
    margin-bottom: 6px;
}
.banner-notification-last{
    margin-bottom: 12px;
}

.banner-notification h3{
    font-weight: bold;
    margin-bottom: 6px;
}

.banner-text{
    margin-bottom: 12px;
}
.banner-toggle-container{
    position: absolute;
    margin-left: -10px;
    padding-left: 5px;
    margin-top: 4px;
    padding-top: 5px;
    height: 30px;
    width: 20px;
    cursor: pointer;
}
.banner-toggle-container .banner-arrow{
    font-weight: bold;
}
.banner-content-container{
    margin-left: 10px;
    position: relative;
    overflow: hidden;

}
.banner-notification.collapsed-banner{
    padding-top: 0;
    padding-bottom: 0;
}
.collapsed-banner{
    overflow: hidden;
}
.collapsed-banner .banner-title{
    font-size: 14px;
    display: block;
    position: relative;
    float: left;
}
.collapsed-banner .banner-text{
    display: none;
}
.collapsed-banner .banner-link{
    display: block;
    position: relative;
    float: left;
    margin-left: 15px;
}

.collapsed-banner .btn{
    margin-top: 5px;
}

.hidden {
    display: none;
}

/*  Custom badge colors. Only colors should be defined here.
All other styles are defined by Bootstrap
    */
.badge-category-local,
.badge-category-protect {background-color: #c43e2a;}
.badge-category-brand {background-color: #283481;}


.clear_divider {
    clear: both;
    margin-bottom: 5px;
    border-bottom: 1px solid #DDDEDE;
}

.photo-summary-preview {
    background-color: white;
    padding: 3px;
    font-family:"lucida grande",tahoma,verdana,arial,sans-serif;
    font-size:11px;
    line-height:13px;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    border: 1px solid #D1D3D4;
}

.photo-summary-pre > div {
    border: 1px #dbdcdc solid;
    min-height: 110px;
    padding:5px;
}

.photo-summary-preview img {
    float: left;
    margin-right: 8px;
    background-color:#EDEFEF;
    width:100px;
}

.post-count {
    margin-top: 0;
}

.overflow-scroll {
    overflow: auto;
}

.pseudo-div-list {
    margin: 0;
    padding: 0;
}

.pseudo-div-list-item {
    margin: 0;
    padding-left: 15px;
}

.post-pseudo-div-list-text {
    margin-top: 15px;
}
